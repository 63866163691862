<template>
  <el-dialog title="初始化数据" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <el-form :model="AddLiveFrom" label-width="100px" ref="AddLiveFrom">
      <el-row :gutter="26">
        <el-col :span="6">
          <el-form-item label="住房地址" width="120px" prop="housingEntity" v-if="editShow">
            <el-select
              v-model="housingEntity"
              filterable
              placeholder="请选择"
              style="width:70%"
              value-key="id"
              @change="getHousing"
              v-if="editShow"
            >
              <el-option
                v-for="item in houstingOption"
                :key="item.id"
                :label="item.detailedAddress"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="住房地址" width="120px" prop="housingAddress" v-else>
            <el-input style="width:230px" v-model="AddLiveFrom.housingAddress" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开始月份" width="120px" prop="recodeMonth">
            <el-date-picker
              v-model="AddLiveFrom.recodeMonth"
              type="date"
              placeholder="选择月"
              value-format="yyyy-MM-dd"
              @blur="getLastLive(AddLiveFrom.recodeMonth)"
              :disabled="!editShow"
            ></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="网费" width="120px" prop="networkMoney">
            <el-input-number
              width="20px"
              v-model="AddLiveFrom.networkMoney"
              @change="coutAllMoney"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="房租费" width="120px" prop="liveMoney">
            <el-input-number width="20px" v-model="AddLiveFrom.liveMoney" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <!--  电费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="当月表计电费度数" label-width="140px" prop="powerFare">
            <el-input-number width="20px" v-model="AddLiveFrom.powerFare" @change="coutPower" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="当月电费度数" width="80px" prop="monthPower">
            <el-input width="20px" v-model="AddLiveFrom.monthPower" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="电费钱" width="80px" prop="powerMoney">
            <el-input-number width="20px" v-model="AddLiveFrom.powerMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <!--  水费  -->
      <el-row :gutter="26">
       
        <el-col :span="6">
          <el-form-item label="当月表计水费度数" label-width="140px" prop="waterFare">
            <el-input-number width="20px" v-model="AddLiveFrom.waterFare" @change="countWater" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="当月水费度数" width="80px" prop="monthWater">
            <el-input width="20px" v-model="AddLiveFrom.monthWater" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="水费钱" width="80px" prop="waterMoney">
            <el-input-number width="20px" v-model="AddLiveFrom.waterMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <!--  天然气  -->
      <el-row :gutter="26">
        <el-col :span="6">
          <el-form-item label="当月表计天然气度数" label-width="140px" prop="gasFare">
            <el-input-number width="20px" v-model="AddLiveFrom.gasFare" @change="coutGas" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="当月天然气度数" width="80px" prop="monthGas">
            <el-input width="20px" v-model="AddLiveFrom.monthGas" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="天然气费用" width="80px" prop="gasMoney">
            <el-input-number width="20px" v-model="AddLiveFrom.gasMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="物业费" width="120px" prop="propertyFee">
            <el-input width="20px" v-model="AddLiveFrom.propertyFee" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="垃圾费" width="120px" prop="rubbishFee">
            <el-input width="20px" v-model="AddLiveFrom.rubbishFee" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="个人部分" width="120px" prop="myMoney">
            <el-input-number width="20px" v-model="AddLiveFrom.myMoney" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="当月总费用" width="120px" prop="liveAllMoney">
            <el-input-number width="20px" v-model="AddLiveFrom.liveAllMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="备注" width="120px" prop="remark">
            <el-input v-model="AddLiveFrom.remark" style="width:800px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="AddLiveInfo()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatDate } from "@/plugins/data.js";
import { selectByIdStatus, listDictApi } from "@/api/my/myconfig/xunLife.js";
import {
  getLastLiveApi,
  addLive,
  queryById,
  editLive
} from "@/api/life/life/live.js";
export default {
  name: "AddLive",
  data() {
    return {
      housingEntity: "",
      houstingOption: [],
      housinId: null,
      dialogVisible: false,
      powerFares: null,
      waterFares: null,
      recodeMonths: null,
      nowWaterFares: null,
      noewPowerFares: null,
      housingAddress: null,
      AddLiveFrom: {
        liveMoney: 0,
        recodeMonth: null,
        powerFare: 0,
        waterFare: 0,
        powerMoney: 0,
        networkMoney: 0,
        waterMoney: 0,
        monthGas: 0,
        remark: null,
        myMoney: 0,
        gasMoney: 0,
        rubbishFee: 0,
        propertyFee: 0
      },
      pay: 1,
      power: 1,
      water: 1,
      //计算费用 电,水,天然气
      powerBaseFree: 0,
      waterBaseFree: 0,
      gasBaseFree: 0,
      //上月
      lastPowerDegree: 0,
      lastWaterDegree: 0,
      lastGasDegree: 0,
      //本月
      powerDegree: 0,
      waterDegree: 0,
      gasDegree: 0,
      editShow: true,
      canType: 1
    };
  },
  methods: {
    //打开弹窗
    showFund(id, type) {
      this.dialogVisible = true;
      if (1 == type) {
        this.housingListButton();
      }
      if (2 == type) {
        this.queryByIdButton(id);
        this.editShow = false;
      }
      this.canType = type;
    },
    queryByIdButton(id) {
      queryById(id).then(res => {
        this.AddLiveFrom = res.result;
        this.lastPowerDegree = res.result.lastPowerFares;
        this.lastWaterDegree = res.result.lastWaterFares;
        this.lastGasDegree = res.result.lastGasFare;
        this.powerBaseFree = res.result.power;
        this.waterBaseFree = res.result.water;
        this.gasBaseFree = res.result.gasFee;
      });
    },

    housingListButton() {
      listDictApi({status:2}).then(res => {
        this.houstingOption = res.result.records;
      });
    },
    getLastLive(recodeMonths) {
    },
    //
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.housingEntity = "";
      this.lastPowerDegree = 0;
      this.lastGasDegree = 0;
      this.lastWaterDegree = 0;
      this.powerDegree = 0;
      this.waterDegree = 0;
      this.gasDegree = 0;
      this.rubbishFee = 0;
      this.editShow = true;
      this.$emit("closeModal", false);
      this.$refs["AddLiveFrom"].resetFields();
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    AddLiveInfo() {
      if (this.AddLiveFrom.recodeMonth == null) {
        return this.$message.error("记录月份不能为空");
      }
      console.log(this.housinId, "===", this.housingAddress);
      this.AddLiveFrom.housingId = this.housinId;
      this.AddLiveFrom.housingAddress = this.housingAddress;
      if (this.canType == 1) {
        addLive(this.AddLiveFrom).then(res => {
          //调用父组件的刷新方法--需要处理
          this.cancel();
          this.$parent.getLifeLiveList();
        });
      }
      if (this.canType == 2) {
        editLive(this.AddLiveFrom).then(res => {
          //调用父组件的刷新方法--需要处理
          this.cancel();
          this.$parent.getLifeLiveList();
        });
      }
    },
    countWater() {
      if (this.lastWaterDegree > this.AddLiveFrom.waterFare) {
        this.AddLiveFrom.waterFare = 0;
        return this.$message.error("不能小于上月度数");
      }
      this.AddLiveFrom.monthWater =
        this.AddLiveFrom.waterFare - this.lastWaterDegree;
      this.AddLiveFrom.waterMoney = Number(
        this.AddLiveFrom.monthWater * this.waterBaseFree
      );
      this.coutAllMoney();
    },
    coutPower() {
      if (this.lastPowerDegree > this.AddLiveFrom.powerFare) {
        this.AddLiveFrom.powerFare = 0;
        return this.$message.error("不能小于上月度数");
      }
      this.AddLiveFrom.monthPower =
        this.AddLiveFrom.powerFare - this.lastPowerDegree;
      this.AddLiveFrom.powerMoney = Number(
        this.AddLiveFrom.monthPower * this.powerBaseFree
      );
      this.coutAllMoney();
    },
    coutGas() {
      if (this.lastGasDegree > this.AddLiveFrom.gasFare) {
        this.AddLiveFrom.gasFare = 0;
        return this.$message.error("不能小于上月度数");
      }
      this.AddLiveFrom.monthGas = this.AddLiveFrom.gasFare - this.lastGasDegree;
      this.AddLiveFrom.gasMoney = Number(
        this.AddLiveFrom.monthGas * this.gasBaseFree
      );
      this.coutAllMoney();
    },

    //同时重新计算
    coutAllMoney() {
      //当月总费用
      this.AddLiveFrom.liveAllMoney =
        Number(this.AddLiveFrom.powerMoney) +
        Number(this.AddLiveFrom.waterMoney) +
        Number(this.AddLiveFrom.networkMoney) +
        Number(this.AddLiveFrom.liveMoney) +
        Number(this.AddLiveFrom.gasMoney) +
        Number(this.AddLiveFrom.rubbishFee) +
        Number(this.AddLiveFrom.propertyFee);
      //自付部分
      this.AddLiveFrom.myMoney = Number(
        this.AddLiveFrom.liveAllMoney * this.pay
      );
    },
    // 选择房子
    getHousing(value) {
      //重置网费  物业费 房租费  以及水电费的计算 还有个人部分的计算
      this.AddLiveFrom.networkMoney = value.networkMoney;
      this.AddLiveFrom.propertyFee = value.propertyFee;
      this.AddLiveFrom.rubbishFee = value.rubbishFee;
      this.pay = value.pay;
      this.AddLiveFrom.liveMoney = Number(value.rent);
      this.AddLiveFrom.myMoney = Number(value.rent) / Number(value.pay);
      this.powerBaseFree = value.power;
      this.waterBaseFree = value.water;
      this.gasBaseFree = value.gasFee;
      this.housinId = value.id;
      this.housingAddress = value.detailedAddress;
      //需要置空 上月电费 上月水费
      this.powerFares = 0;
      this.waterFares = 0;
      this.AddLiveFrom.powerFare = 0;
      this.AddLiveFrom.waterFare = 0;
      this.AddLiveFrom.powerMoney = 0;
      this.AddLiveFrom.waterMoney = 0;
      this.nowWaterFares = 0;
      this.noewPowerFares = 0;

      //下面要删除
      this.power = value.power;
      this.water = value.water;
      this.coutAllMoney();
    }
  }
};
</script>
<style lang="less" scoped>
</style> 