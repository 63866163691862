<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <span class="demonstration">住房地址：</span>
          <el-select v-model="queryParams.housingId" placeholder="请选择" size="medium">
            <el-option
              v-for="item in houstingOption"
              :key="item.id"
              :label="item.detailedAddress"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <span class="demonstration">记录月份：</span>
          <el-date-picker type="month" v-model="queryParams.recodeMonth" placeholder="选择月"></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="getLifeLiveList()">查询</el-button>
          <el-button type="primay" @click="resteLifeLiveList()">重置</el-button>
        </el-col>
      </el-row>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin:9px 2px">
        <el-button type="warning" icon="el-icon-add" size="min" @click="addInitButton">设置初始数据</el-button>
        <el-button type="warning" icon="el-icon-add" size="min" @click="addLive">新增</el-button>
        <el-button type="warning" icon="el-icon-edit" size="min" @click="aeditLive">修改</el-button>
      </el-row>
      <!-- 列表区域 -->
      <el-table :data="liveList" border stripe show-summary>
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="记录月份" prop="recodeMonth" />
        <el-table-column label="住房地址" prop="housingAddress" width="200px" />
        <el-table-column label="电费度数" prop="monthPower" />
        <el-table-column label="水费度数" prop="monthWater" />
        <el-table-column label="电费" prop="powerMoney" />
        <el-table-column label="水费钱" prop="waterMoney" />
        <el-table-column label="网费" prop="networkMoney" />
        <el-table-column label="房租费" prop="liveMoney" />
        <el-table-column label="个人部分" prop="myMoney" />
        <el-table-column label="当月费用" prop="liveAllMoney" />
        <el-table-column label="备注" prop="remark" width="400px" />
      </el-table>
      <pagination
            :total="queryParams.total"
            :page-size.sync="queryParams.pageSize"
            :current-page.sync="queryParams.currentPage"
          />
    </el-card>
    <AddLive ref="addModules"></AddLive>
    <initData ref="initDataFrom"></initData>
  </div>
</template>

<script>
import format from "@/plugins/format.js";
import AddLive from "./modules/AddLive";
import initData from "./modules/initData";
import {
  listDictApi,
  
} from "@/api/my/myconfig/xunLife.js";
import { liveList,judgeIsBasicsApi } from "@/api/life/life/live.js";
export default {
  name: "liveList",
  components: {
    AddLive,
    initData
  },
  data() {
    return {
      liveList: [],
      houstingOption: [],
      radio: "",
      queryParams: {
        recodeMonth: null,
        housingId: null,
        currentPage: 1,
        pageSize: 10,
        total: 0,
      }
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.getLifeLiveList();
    },
    "queryParams.pageSize"(val) {
      this.getLifeLiveList();
    },
  },
  created() {
    this.getLifeLiveList();
    this.housingListButton();
  },
  methods: {
    housingListButton() {
      listDictApi().then(res => {
        this.houstingOption = res.result.records;
      });
    },
    async getLifeLiveList() {
      if (this.queryParams.recodeMonth != null) {
        this.queryParams.recodeMonth = format(
          this.queryParams.recodeMonth,
          "YYYY-MM"
        );
      }
      liveList(this.queryParams).then(res => {
        this.liveList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    addLive() {
      this.$refs.addModules.showFund(null, 1);
    },
    aeditLive() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      this.$refs.addModules.showFund(this.id, 2);
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.templateSelection = row;
    },
    resteLifeLiveList() {
      this.queryParams.recodeMonth = null;
      this.queryParams.housingId = null;
      this.getLifeLiveList();
    },
    addInitButton() {
       judgeIsBasicsApi().then(res => {
         this.$refs.initDataFrom.showFund(null, 1);
      });
    }
  }
};
</script>

<style lang="less" scoped>
</style> 